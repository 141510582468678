export class ColorPallete {
  constructor(obj) {
    this.ctaBgColor = obj.ctaBgColor;
    this.ctaTextColor = obj.ctaTextColor;
    this.headerBgColor = obj.headerBgColor;
    this.headerTextColor = obj.headerTextColor;
    this.offerwallBgColor = obj.offerwallBgColor;
    this.offerwallTextColor = obj.offerwallTextColor;
  }

  static from(obj) {
    if (!obj) return null;
    return new ColorPallete({
      ctaBgColor: obj.cta_bg_color,
      ctaTextColor: obj.cta_text_color,
      headerBgColor: obj.header_bg_color,
      headerTextColor: obj.header_text_color,
      offerwallBgColor: obj.offerwall_bg_color,
      offerwallTextColor: obj.offerwall_text_color
    });
  }

  static fromAll(data) {
    return data.map(ColorPallete.from);
  }
}
