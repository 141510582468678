import { ColorPallete } from '@/model/app';
import { TrackingData } from '@/model/user';
import { StorageService } from './StorageService';

export const AndroidService = {
  isNoWindow:
    typeof window === 'undefined' || typeof window.Android === 'undefined',

  offerWallShowed(...args) {
    if (this.isNoWindow) return;
    return window.Android.offerWallShowed(...args);
  },
  rewardClaimed(...args) {
    if (this.isNoWindow) return;
    return window.Android.rewardClaimed(...args);
  },
  encrypt(...args) {
    if (this.isNoWindow) return;
    const userId = StorageService.get('user_id', true);
    return window.Android.encrypt(userId, ...args);
  },
  logOfferClicked(...args) {
    if (this.isNoWindow) return;
    return window.Android.logOfferClicked(...args);
  },
  closeOfferWall(...args) {
    if (this.isNoWindow) return;
    return window.Android.closeOfferWall(...args);
  },
  offerAppInstalled(...args) {
    if (this.isNoWindow) return;
    return window.Android.offerAppInstalled(...args);
  },
  isAppInstalled(...args) {
    if (this.isNoWindow) return;
    return window.Android.isAppInstalled(...args);
  },
  startOffer(...args) {
    if (this.isNoWindow) return;
    return window.Android.startOffer(...args);
  },
  getOfferWallTitle(...args) {
    if (this.isNoWindow) return;
    return window.Android.getOfferWallTitle(...args);
  },
  getTrackingData(...args) {
    if (this.isNoWindow) return;
    return TrackingData.from(
      JSON.parse(window.Android.getTrackingData(...args))
    );
  },
  getColorPalette(...args) {
    if (this.isNoWindow) return;
    const colorPallete = window.Android.getColorPalette(...args);
    if (!colorPallete) return null;
    return ColorPallete.from(JSON.parse(colorPallete));
  },
  getAppIconUrl(...args) {
    if (this.isNoWindow) return;
    return window.Android.getAppIconUrl(...args);
  },
  getCurrencyIconUrl(...args) {
    if (this.isNoWindow) return;
    return window.Android.getCurrencyIconUrl(...args);
  },
  getCurrencyName(...args) {
    if (this.isNoWindow) return;
    return window.Android.getCurrencyName(...args);
  },
  getOfferWallTitle(...args) {
    if (this.isNoWindow) return;
    return window.Android.getOfferWallTitle(...args);
  },
  getAppName(...args) {
    if (this.isNoWindow) return;
    return window.Android.getAppName(...args);
  },
  getAppKey(...args) {
    if (this.isNoWindow) return;
    return window.Android.getAppKey(...args);
  },
  getAndroidVersion(...args) {
    if (this.isNoWindow) return;
    return window.Android.getAndroidVersion(...args);
  },
  getPackageName(...args) {
    if (this.isNoWindow) return;
    return window.Android.getPackageName(...args);
  },
  getBulidNumber(...args) {
    if (this.isNoWindow) return;
    return window.Android.getBulidNumber(...args);
  },
  openUrlInBrowser(...args) {
    if (this.isNoWindow) return;
    return window.Android.openUrlInBrowser(...args);
  },
  isUsageStatsPermissionGranted(...args) {
    if (this.isNoWindow) return;
    return window.Android.isUsageStatsPermissionGranted(...args);
  },
  getUsageAccessPermission(...args) {
    if (this.isNoWindow) return;
    return window.Android.getUsageAccessPermission(...args);
  },
  getOrientation(...args) {
    if (this.isNoWindow) return;
    return window.Android.getOrientation(...args); //LANDSCAPE, PORTRAIT
  },
  showToast(...args) {
    if (this.isNoWindow) return;
    return window.Android.showToastMessage(...args); //LANDSCAPE, PORTRAIT
  },
  wasAppOpenedToday(...args) {
    if (this.isNoWindow) return;
    return window.Android.wasAppOpenedToday(...args);
  },
  launchOfferApp(...args) {
    if (this.isNoWindow) return;
    return window.Android.launchOfferApp(...args);
  },
  getUserDeviceName(...args) {
    if (this.isNoWindow) return;
    return window.Android.getUserDeviceName(...args);
  },
  logEvent(...args) {
    if (this.isNoWindow) return;
    try {
      return window.Android.logEvent(...args);
    } catch (err) {
      console.error(err);
    }
  },
  addAnalyticsUserParam(...args) {
    if (this.isNoWindow) return;
    try {
      return window.Android.addAnalyticsUserParam(...args);
    } catch (err) {
      console.error(err);
    }
  },
  isDebugEnabled(...args) {
    if (this.isNoWindow) return;
    try {
      return window.Android.isDebugEnabled(...args);
    } catch (err) {
      console.error(err);
    }
  },
  getSDKVersion(...args) {
    if (this.isNoWindow) return;
    return window.Android.getSDKV(...args);
  },
  getSDKNumber(...args) {
    if (this.isNoWindow) return;
    return window.Android.getSDKN(...args);
  },
  isVPNEnabled(...args) {
    if (this.isNoWindow) return;
    if (typeof window.Android.isVPNEnabled === 'undefined') return false;
    return window.Android.isVPNEnabled(...args);
  }
};
