export class TrackingData {
  constructor(obj) {
    this.androidId = obj.androidId;
    this.appSetId = obj.appSetId;
    this.deviceModel = obj.deviceModel;
    this.drmId = obj.drmId;
    this.gaId = obj.gaId;
    this.gsfId = obj.gsfId;
    this.uniqueId = obj.uniqueId;
    this.packageId = obj.packageId;
  }

  static from(obj) {
    if (!obj) return null;
    return new TrackingData({
      androidId: obj.android_id,
      appSetId: obj.app_set_id,
      deviceModel: obj.device_model,
      drmId: obj.drm_id,
      gaId: obj.ga_id,
      gsfId: obj.gsf_id,
      uniqueId: obj.unique_id,
      packageId: obj.package_id
    });
  }

  static toHttpObject(obj) {
    if (!obj) return null;
    return {
      android_id: obj.androidId,
      app_set_id: obj.appSetId,
      device_model: obj.deviceModel,
      drm_id: obj.drmId,
      ga_id: obj.gaId,
      adv_id: obj.gaId,
      gsf_id: obj.gsfId,
      unique_id: obj.uniqueId,
      p_id: obj.packageId
    };
  }
  static toHttpGetUserObject(obj) {
    if (!obj) return null;
    return {
      android_id: obj.androidId,
      app_set_id: obj.appSetId,
      device_model: obj.deviceModel,
      drm_id: obj.drmId,
      ga_id: obj.gaId,
      adv_id: obj.gaId,
      gsf_id: obj.gsfId,
      unique_id: obj.uniqueId,
      package_id: obj.packageId
    };
  }
}

export class User {
  constructor(obj) {
    this.authToken = obj.authToken;
    this.refreshToken = obj.refreshToken;
    this.userId = obj.userId;
    this.wallets = obj.wallets;
  }

  static from(obj) {
    if (!obj) return null;
    return new User({
      authToken: obj.auth_token,
      refreshToken: obj.refresh_token,
      userId: obj.user_id,
      wallets: Wallet.fromAll(obj.wallets)
    });
  }
}

export class Wallet {
  constructor(obj) {
    this.label = obj.label;
    this.wid = obj.wid;
  }

  static from(obj) {
    if (!obj) return null;
    return new Wallet({
      label: obj.label,
      wid: obj.wid
    });
  }

  static fromAll(data) {
    if (!data) return null;
    return data.map(Wallet.from);
  }
}

export class Profile {
  constructor(obj) {
    this.profileId = obj.profileId;
  }

  static from(obj) {
    if (!obj) return null;
    return new Profile({
      profileId: obj.profile_id
    });
  }
}

export class UserEarning {
  constructor(obj) {
    /**
     *  @type {_Earning}
     */
    this.totalEarned = obj.totalEarned;
  }

  static from(obj) {
    if (!obj) return null;
    return new UserEarning({
      totalEarned: _Earning.from(obj.total_earned)
    });
  }
}

export class _Earning {
  constructor(obj) {
    this.amount = obj.amount;
    this.currency = obj.currency;
  }

  static from(obj) {
    if (!obj) return null;
    return new _Earning({
      amount: obj.amount,
      currency: obj.currency
    });
  }
}
